import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import type { StyleFunctionProps } from '@chakra-ui/styled-system'

// Version 1: Using objects
const theme = extendTheme({
  styles: {
    colors: {
      brand: '#151313'
    },
    global: {
      // styles for the `body`
      body: {
        bg: '#f7f4f1',
        color: '#151313',
      },
      
      
    },
  },
})

export default theme;