import React from 'react';
import { Box, Container, Heading, Text, VStack } from '@chakra-ui/react';
import Navbar from '../Components/NavigationBar';

const Support: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#f7f4f1', minHeight: '100vh' }}>
      <Navbar />
      <Container maxWidth="md" sx={{ pt: 8, pb: 6 }}>
        <Heading>
          Support
        </Heading>
        <Text>
          For support, contact info@findishq.com via email or DM us on Instagram at @findishq. We will do our best to respond as soon as possible.
        </Text>
      </Container>
    </Box>
  );
};

export default Support;


