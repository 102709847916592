import { FC } from "react";
import { Grid, GridItem, Image, Link, Box, useBreakpointValue, Divider, Text } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';

import NavigationBar from "../Components/NavigationBar";

type BlogEntry = {
  id: number;
  author: string;
  title: string;
  date: string;
  readTime: string;
  imageUrl: string;
  linkUrl: string;
};

// Sample data for blog entries
const blogEntries: BlogEntry[] = [
  {
    id: 0,
    author: "Sabeen Khan",
    title: "Sabeen on: Starting a Company, Moving Back Home, and Dating",
    imageUrl: require(`../Assets/sabeen-blog.png`),
    date: "04/29/2024",
    readTime: "7 minute read",
    linkUrl: "/sabeen-on-dating-parents-founder",
  },
  {
    id: 1,
    author: "Shivkumar Bhadola",
    title: "Opting Out of Options",
    imageUrl: require(`../Assets/opting-out.png`),
    date: "04/1/2024",
    readTime: "5 minute read",
    linkUrl: "/opting-out-of-options",
  },
  {
    id: 2,
    author: "Nisha Mohan",
    title: "Mapping Love: Exploring the Significance of Location in South Asian Dating",
    imageUrl: require(`../Assets/mapping-love.png`),
    date: "02/11/2024",
    readTime: "6 minute read",
    linkUrl: "/mapping-love-exploring-the-significance-of-location-in-south-asian-dating",
  },
  {
    id: 3,
    author: "Sishir Mohan",
    title: "Love by the Numbers: How Ratios Shape the Dating Game",
    imageUrl: require(`../Assets/numbers-love.png`),
    date: "02/04/2024",
    readTime: "5 minute read",
    linkUrl: "/love-by-the-numbers-how-ratios-shape-the-dating-game",
  },


];

const Blog: FC = () => {
  const gridTemplateColumns = useBreakpointValue({ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' });

  const imageWidth = useBreakpointValue({ base: '100%', md: '100%' });
  const imageHeight = useBreakpointValue({ base: '300px', md: '350px' });

  return (
    <div>
      <NavigationBar />
      <div>
        <div className="home-subtext libre-baskerville-italic text-center">
          Weekly musings on dating and love, as we (or others) observe them.
        </div>
        <Divider borderColor="black" />
        <Box p={4}>
          <Grid templateColumns={gridTemplateColumns} gap={6} >
            {blogEntries.map((entry) => (
              <RouterLink to={entry.linkUrl} key={entry.id} style={{ textDecoration: 'none' }}>
                <GridItem
                  w="100%"
                  border="0.2px solid gray"
                  borderRadius="md"
                  p={4}
                  shadow="lg"
                  _hover={{ shadow: "lg", textDecoration: 'none' }} // Optional: Add a hover effect
                >
                  <Image
                    src={entry.imageUrl}
                    alt={entry.title}
                    borderRadius="md"
                    w={imageWidth}
                    h={imageHeight}
                    objectFit="cover"
                  />
                  <Text mt={2} fontSize={10} fontFamily="inter">
                    {entry.date} • {entry.readTime} • {entry.author}
                  </Text>
                  <Text mt={2} fontWeight={600} fontFamily="libre-baskerville">
                    {entry.title}
                  </Text>
                </GridItem>
              </RouterLink>
            ))}
          </Grid>
        </Box>
      </div>


    </div>


  )
}

export default Blog;