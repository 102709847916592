export const IshqLetterLogo = () => (
    
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="25.000000pt" height="25.000000pt" viewBox="0 0 75.000000 53.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,53.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M125 512 c-76 -36 -120 -128 -120 -252 0 -73 4 -93 28 -143 81 -164
250 -152 312 24 9 24 25 80 36 124 41 165 82 230 150 241 84 14 159 -116 159
-277 0 -195 -121 -288 -169 -128 -14 46 -38 61 -57 34 -29 -39 21 -119 81
-131 43 -8 109 21 139 61 45 58 61 113 61 205 0 73 -4 93 -29 143 -61 126
-195 153 -269 55 -30 -39 -49 -89 -78 -203 -41 -165 -82 -230 -150 -241 -84
-14 -159 116 -159 277 0 195 121 288 169 128 14 -46 38 -61 57 -34 18 24 8 63
-25 98 -35 36 -84 43 -136 19z"/>
</g>
</svg>

    

);

