import React from 'react';
import { Box, Container, Heading, Text, VStack, Link } from '@chakra-ui/react';
import { NextUIProvider } from "@nextui-org/react";
import NavigationBar from '../Components/NavigationBar';

const termsOfServiceContent = (
  <>
    <Text className="inter">
      <strong>Terms &amp; Conditions</strong>
    </Text>
    <Text className="inter" mt={4}>
      These terms and conditions applies to the Ishq app (hereby referred to as "Application") for mobile devices that was created by Wedinvyte, Inc (hereby referred to as "Service Provider") as a Commercial service.
    </Text>
    <Text className="inter" mt={4}>
      Upon downloading or utilizing the Application, you are automatically agreeing to the following terms. It is strongly advised that you thoroughly read and understand these terms prior to using the Application. Unauthorized copying, modification of the Application, any part of the Application, or our trademarks is strictly prohibited. Any attempts to extract the source code of the Application, translate the Application into other languages, or create derivative versions are not permitted. All trademarks, copyrights, database rights, and other intellectual property rights related to the Application remain the property of the Service Provider.
    </Text>
    <Text className="inter" mt={4}>
      The Service Provider is dedicated to ensuring that the Application is as beneficial and efficient as possible. As such, they reserve the right to modify the Application or charge for their services at any time and for any reason. The Service Provider assures you that any charges for the Application or its services will be clearly communicated to you.
    </Text>
    <Text className="inter" mt={4}>
      The Application stores and processes personal data that you have provided to the Service Provider in order to provide the Service. It is your responsibility to maintain the security of your phone and access to the Application. The Service Provider strongly advise against jailbreaking or rooting your phone, which involves removing software restrictions and limitations imposed by the official operating system of your device. Such actions could expose your phone to malware, viruses, malicious programs, compromise your phone's security features, and may result in the Application not functioning correctly or at all.
    </Text>
    <Text className="inter" fontWeight="bold" mt={4}>
      Third-Party Services
    </Text>
    <Text className="inter">
      Please note that the Application utilizes third-party services that have their own Terms and Conditions. Below are the links to the Terms and Conditions of the third-party service providers used by the Application:
    </Text>
    <Box as="ul" pl={5} mt={2} className="inter">
      <Box as="li"><Link href="https://policies.google.com/terms" isExternal>Google Play Services</Link></Box>
      <Box as="li"><Link href="https://www.google.com/analytics/terms/" isExternal>Google Analytics for Firebase</Link></Box>
      <Box as="li"><Link href="https://amplitude.com/terms" isExternal>Amplitude</Link></Box>
      <Box as="li"><Link href="https://www.revenuecat.com/terms" isExternal>RevenueCat</Link></Box>
    </Box>
    <Text className="inter" fontWeight="bold" mt={4}>
      Disclaimer of Responsibility
    </Text>
    <Text className="inter">
      Please be aware that the Service Provider does not assume responsibility for certain aspects. Some functions of the Application require an active internet connection, which can be Wi-Fi or provided by your mobile network provider. The Service Provider cannot be held responsible if the Application does not function at full capacity due to lack of access to Wi-Fi or if you have exhausted your data allowance.
    </Text>
    <Text className="inter" mt={4}>
      If you are using the application outside of a Wi-Fi area, please be aware that your mobile network provider's agreement terms still apply. Consequently, you may incur charges from your mobile provider for data usage during the connection to the application, or other third-party charges. By using the application, you accept responsibility for any such charges, including roaming data charges if you use the application outside of your home territory (i.e., region or country) without disabling data roaming. If you are not the bill payer for the device on which you are using the application, they assume that you have obtained permission from the bill payer.
    </Text>
    <Text className="inter" fontWeight="bold" mt={4}>
      Changes to These Terms and Conditions
    </Text>
    <Text className="inter">
      The Service Provider may periodically update their Terms and Conditions. Therefore, you are advised to review this page regularly for any changes. The Service Provider will notify you of any changes by posting the new Terms and Conditions on this page.
    </Text>
    <Text className="inter" mt={4}>
      These terms and conditions are effective as of 2024-08-17
    </Text>
    <Text className="inter" fontWeight="bold" mt={4}>
      Contact Us
    </Text>
    <Text className="inter">
      If you have any questions or suggestions about the Terms and Conditions, please do not hesitate to contact the Service Provider at sishir@findishq.com.
    </Text>
  </>
);

const TermsOfService: React.FC = () => {
  return (
    <NextUIProvider>
      <NavigationBar />
      <Box bg="#f7f4f1" minHeight="100vh" py={12}>
        <Container maxW="container.md">
          <VStack spacing={8} align="stretch">
            <Heading as="h1" size="2xl" textAlign="center" className="libre-regular">
              Terms of Service
            </Heading>
            <Text className="inter">
              {termsOfServiceContent}
            </Text>
          </VStack>
        </Container>
      </Box>
    </NextUIProvider>
  );
};

export default TermsOfService;
