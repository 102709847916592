import { FC } from "react";
import { Button, Link, Text, Heading, Box } from "@chakra-ui/react";
import NavigationBar from "../Components/NavigationBar";

const MappingLove: FC = () => {
    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center gap-4 margin-unit-2 justify-center">
                <Box p={5} mx={{ base: '0', md: '10%', lg: '20%' }}>
                    <Heading as="h1" fontFamily="libre" marginBottom={4}>
                    Mapping Love: Exploring the Significance of Location in South Asian Dating

                    </Heading>
                    <Text fontFamily="Inter" marginBottom={3}>
                    In today's dating landscape, swipes and likes shape our romantic connections, seemingly expanding our dating pool infinitely. Geographical barriers are no longer a constraint unless deliberately filtered on dating apps. Let's delve into two firsthand reasons we've encountered in our conversations for why South Asians may or may not consider location important in their pursuit of love.
                    </Text>
                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                    South Asians often disregard proximity in dating due to challenges in finding compatible partners nearby.
                    </Heading>
                    <Text fontFamily="Inter" marginBottom={3}>
                    Many South Asians we've spoken to see location as insignificant in dating. They've either struggled to find someone nearby who shares their values, or they prioritize quality over proximity. New York City is a prime example where the cultural context often clashes with reality. While its residents are known for their community spirit and ambition, the dating scene, particularly with the prevalence of dating apps, can feel transactional and non-committal. As one person lamented,
                    </Text>
                    <Text fontFamily="libre" marginBottom={3}>
                    "I moved to New York hoping to find my life partner, but instead, I'm lost in a sea of short-term connections." 

                    </Text>
                    <Text fontFamily="Inter" marginBottom={3}>
                    Conversely, those who've returned to less urban areas may struggle to find fellow South Asians and/or like-minded individuals who prioritize ambition over suburban comfort.
                    </Text>
                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                    South Asians prioritize proximity in dating due to the challenges and limitations of long-distance relationships.
                    </Heading>
                    <Text fontFamily="inter" marginBottom={1}>
                    Many who have experienced long-distance relationships are wary of dating those outside their proximity, setting limits on how far they're willing to expand their preferences, whether it's within driving distance or in the same time zone. Failed long-distance relationships are often attributed to the challenges of getting to know someone from afar, requiring extensive forethought, planning, and effort. While frequent communication may foster emotional intimacy, limited time spent together makes it difficult to fully gauge compatibility and authenticity, prolonging the process of getting to know each other.
                    </Text>
                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                    What are your thoughts?
                    </Heading>
                    <Text fontFamily="inter" marginBottom={5}>
                    In a world where technology seemingly erases geographical barriers, South Asians grapple with the significance of proximity in their search for love. Some prioritize compatibility over distance, while others find themselves lost in the complexities of dating scenes like that of New York City. As we navigate these dynamics, we're left wondering: In the pursuit of love, how much does location truly matter? What are your thoughts on the role of proximity in modern dating?
                    </Text>
                    <Text fontFamily="libre" marginBottom={5}>
                        If you enjoy writing about dating, South Asian culture & identity, or any other relevant topics and want to contribute to our blog, email us at info@findishq.com. We would love to feature you.
                    </Text>

                </Box>
                <Button padding={7} fontFamily={'inter'} borderRadius={40} backgroundColor={'#151313'} color={'#f7f4f1'} as={Link} href="/apply" variant="flat" marginBottom={10}>
                    Apply
                </Button>

            </div>

        </div>


    )
}

export default MappingLove;