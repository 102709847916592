import { FC } from "react";
import { Button, Link, Text, Heading, Box, Flex, Image, Divider } from "@chakra-ui/react";
import NavigationBar from "../Components/NavigationBar";
import SpotifyLogo from "../Assets/spotify-logo.png"
import TiktokLogo from "../Assets/tiktok-logo.png"
import TwitterLogo from "../Assets/twitter-logo.png"
import InstagramLogo from "../Assets/instagram-logo.png"

const OptingOutOfOptions: FC = () => {
    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center gap-4 margin-unit-2 justify-center">
                <Box p={5} mx={{ base: '0', md: '10%', lg: '20%' }}>
                    <Heading as="h1" fontFamily="libre" marginBottom={4}>
                        Opting Out of the Options
                    </Heading>
                    <Text id="byline" fontFamily="libre" marginBottom={5}>
                        Written by Dr. Shiv Bhadola, who is a neurologist currently based in Manhattan. In his free time, he is writing his first book on experiences as a healthcare trainee through COVID, performing slam poetry and stand up comedy, and thinking of ideas for a second mixtape. Otherwise, you can find him wondering when Boston sports will return to glory.
                    </Text>
                    <Flex justify="flex-start" gap="20px" marginBottom={5}>
                        <Link href="https://www.instagram.com/mc_kumar" isExternal>
                            <Image boxSize="20px" src={InstagramLogo} alt="Instagram" />
                        </Link>
                        <Link href="https://twitter.com/shivbhadola" isExternal>
                            <Image boxSize="20px" src={TwitterLogo} alt="Twitter" />
                        </Link>
                        <Link href="https://www.tiktok.com/@mc_kumar4" isExternal>
                            <Image boxSize="20px" src={TiktokLogo} alt="TikTok" />
                        </Link>
                        <Link href="https://open.spotify.com/artist/4mld8D85BjJgKKgtat5Q2d?si=SUbTqqGYQV6N8yQBEi62dg" isExternal>
                            <Image boxSize="20px" src={SpotifyLogo} alt="Spotify" />
                        </Link>
                    </Flex>
                    <Divider />
                    <Text fontFamily="Inter" marginBottom={3} marginTop={3}>
                        Most of us are in cities; for those of us who are not, many are planning to move into or near a medium- to large-sized city. Part of the allure and perceived perks for single folk is the sheer number of individuals in these cities. And this ranges from New York City, from where this article is being written with a population well above eight million, to Chicago, Los Angeles, and down to less dense areas like Cleveland, Tampa, and Denver.
                    </Text>

                    <Text fontFamily="Inter" marginBottom={3}>
                        Cities, however, often give the illusion of company and greater success at discovering new, intimate relationships. Especially in NYC, and in many other metropolitan areas, dating seems more difficult than elsewhere (see recent hype of finding a farmer). The standup comedy circuit in NYC commonly features comics, including myself, displaying the dating scene with sardonicism one can find on Saturday Night Live, often to the audience’s delight more than with other topics.
                    </Text>

                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                        What gives? Why do we predict living in or next to a city will make finding a match easier, and why are we wrong?
                    </Heading>


                    <Text fontFamily="Inter" marginBottom={3}>
                        Dating in NYC comes with a phrase: next one, best one. There’s a general belief that perfection is out there and that every empty checkbox can be marked off like a ballot with one candidate for each position. Staunch disagreement with Sima Auntie’s suggestion of settling down with a fraction of the full expectation leads to an endless search as a rebuke of settling. Those who consider themselves to be seriously dating and between their mid-twenties to late thirties not uncommonly point out flaws or what may be lacking in a potential partner or date. The concept of being satisfied or content with what may be enough is forgone. What should be needless to say: this is a generalization and there are those who find successful partnerships in cities; I’m speaking towards those who may be struggling with dating.
                    </Text>
                    <Text fontFamily="Inter" marginBottom={3}>
                        The South Asian Millennial to Gen Z community is no stranger to long distance dating. Perhaps we prefer quality and believe it comes from searching for what we desire in various pockets of the country or globe; but when one adds in the sheer quantity of options available through all sorts of dating apps, the end product is typically neither ideal nor desired.
                    </Text>

                    <Text fontFamily="inter" marginBottom={1}>
                        If we have less options, it is not unreasonable to believe we would have less confusion. If we were engaged in a dialogue with one individual for a period of time and could not chat with others - at least in one given medium - unless leaving this person altogether, without possibility of breadcrumbing, we might find satisfaction more easily.
                    </Text>

                    <Text fontFamily="inter" marginBottom={5}>
                        Our lives are busy and cluttered, and we are inundated with requests and work constantly. Having to filter through millions of options with varying degrees of compatibility cannot be ideal for someone with intention of a serious partnership; instead, a smaller cohort of quality, like-minded individuals sounds like a better bet.
                    </Text>
                    <Text fontFamily="libre" marginBottom={5}>
                        If you enjoy writing about dating, South Asian culture & identity, or any other relevant topics and want to contribute to our blog, email us at info@findishq.com. We would love to feature you.
                    </Text>

                </Box>
                <Button padding={7} fontFamily={'inter'} borderRadius={40} backgroundColor={'#151313'} color={'#f7f4f1'} as={Link} href="/apply" variant="flat" marginBottom={10}>
                    Apply
                </Button>

            </div>

        </div>


    )
}

export default OptingOutOfOptions;