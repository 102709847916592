import { FC } from "react";
import { Button, Link, Text, Heading, Box, Flex, Image, Divider } from "@chakra-ui/react";
import NavigationBar from "../Components/NavigationBar";
import SpotifyLogo from "../Assets/spotify-logo.png"
import TiktokLogo from "../Assets/tiktok-logo.png"
import TwitterLogo from "../Assets/twitter-logo.png"
import InstagramLogo from "../Assets/instagram-logo.png"

const SabeenBlog: FC = () => {
    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center gap-4 margin-unit-2 justify-center">
                <Box p={5} mx={{ base: '0', md: '10%', lg: '20%' }}>
                    <Heading as="h1" fontFamily="libre" marginBottom={4}>
                        Sabeen on: Starting a Company, Moving Back Home, and Dating
                    </Heading>
                    <Text id="byline" fontFamily="libre" marginBottom={5}>
                        Written by Sabeen Khan, who is the founder of Khoobi, a pioneering app that enhances transparency and rewards in the beauty and wellness industry. Originally from New York, Sabeen attended the University of Michigan, where she honed her skills and passion for entrepreneurship. She is passionate about content creation, using her platform to influence and inspire a dynamic audience.
                    </Text>
                    <Flex justify="flex-start" gap="20px" marginBottom={5}>
                        <Link href="https://www.instagram.com/thesabway/" isExternal>
                            <Image boxSize="20px" src={InstagramLogo} alt="Instagram" />
                        </Link>
                        <Link href="https://www.tiktok.com/@thesabway" isExternal>
                            <Image boxSize="20px" src={TiktokLogo} alt="TikTok" />
                        </Link>
                    </Flex>
                    <Divider />
                    <Text fontFamily="Inter" marginBottom={3} marginTop={3}>
                    I’ve hid all of my relationships from my parents. I hid a 2.5 year, long distance relationship in early college. I hid another 2 year relationship during my senior year. My parents had always made it clear that they did not want their kids dating and they didn’t want to meet our partners until we were engaged. As the only daughter, growing up I had stricter curfews, Life360 phone tracking and constant, frantic phone calls of where I was and when I’d be coming home. So you can imagine how jarring it must be, now at age 25, to be receiving the most pressure from my parents to get married. 
                    </Text>

                    <Text fontFamily="Inter" marginBottom={3}>
                    I left my corporate job a few months ago to start building Khoobi. An app that is bringing price transparency, social recommendations and rewards to the beauty and wellness space. While it’s been an exciting opportunity, I had to forgo my independence and move back in with my Pakistani parents. Living at home has halted my dating life, as much as my parents don’t want it to. Growing up, my parents were so against my dating life that it is ingrained in me to keep it quiet and a secret. I can never feel comfortable even telling my parents that I am going on a date. However, since moving home my parents have become more insistent that I start meeting people to “find someone.” They have an expectation that I need to meet someone nice that I can get married to, have kids with and start a family. The other day, my mom was actually upset when I told her I wouldn’t be attending Ishq’s connections event after posting a TikTok about it. 
                    </Text>

                    <Text fontFamily="Inter" marginBottom={3}>
                    I don’t know how to comfortably explore a relationship, knowing that I'd have to come back to my parents' thoughts, questions and concerns. We also have very different stances around dating. I want long term relationships, a long engagement and am nowhere ready now if ever to have children. I’ve already felt pressured to just “pick someone” to have a family with and I’m worried the relationship would move according to my parent’s timeline and not mine. 
                    </Text>
                    <Text fontFamily="Inter" marginBottom={3}>
                    My job situation doesn’t help either. The South Asian community is made of some of the most career oriented, intelligent and ambitious people I know. Unfunded, pre-launch, female entrepreneurs don't receive kind looks from the people in my community, which is why my mom has asked me several times to go get my MBA. I am terrified of what first dates will look like and am insecure about if I'll come across as someone who doesn’t have it all together. 
                    </Text>

                    <Text fontFamily="inter" marginBottom={1}>
                    Despite the hurdles and high expectations, I see a silver lining. Living at home has given me a unique vantage point to understand and appreciate the cultural nuances that shape our identities and relationships. I’ve had the opportunity to spend more time with my parents, speaking Urdu and eating desi food. I’ve been able to fully participate in Ramadan and Eid without fear of missing corporate responsibilities. I have also had the most insight into my parents marriage and family dynamics than ever before. I see their good days, bad days and what inspires their desires for what I may need in a future partner. 
                    </Text>

                    <Text fontFamily="inter" marginBottom={5}>
                    It's here, in this complex interplay of tradition and personal desires, that I find the courage to define what happiness means to me. As I navigate the intertwined paths of personal growth and romantic exploration, I'm learning to balance respect for my roots and culture with the pursuit of my own authentic love story. 
                    </Text>

                    <Text fontFamily="inter" marginBottom={5}>
                    With Ishq, a platform tailored to our cultural context, I'm optimistic about meeting someone who resonates with both my aspirations and my heritage. I envision a future where I can forge a connection that honors my values and timelines, someone who understands the dance between independence and interdependence. 
                    </Text>

                    <Text fontFamily="inter" marginBottom={5}>
                    As I continue to build Khoobi and carve out my own space in the world, I'm reminded that every challenge is also an opportunity—an opportunity to grow, to learn, and to love more deeply. With each step, I grow more ready to meet not just anyone, but the right one.
                    </Text>

                    <Text fontFamily="libre" marginBottom={5}>
                        If you enjoy writing about dating, South Asian culture & identity, or any other relevant topics and want to contribute to our blog, email us at info@findishq.com. We would love to feature you.
                    </Text>


                </Box>
                <Button padding={7} fontFamily={'inter'} borderRadius={40} backgroundColor={'#151313'} color={'#f7f4f1'} as={Link} href="/apply" variant="flat" marginBottom={10}>
                    Apply
                </Button>

            </div>

        </div>


    )
}

export default SabeenBlog;