import React from 'react';
import { Box, Container, Heading, Text, VStack, Link } from '@chakra-ui/react';
import { NextUIProvider } from "@nextui-org/react";
import NavigationBar from '../Components/NavigationBar';

const termsOfUseContent = (
  <>
    <Text className="inter">
      <strong>Licensed Application End User License Agreement</strong>
    </Text>
    <Text className="inter" mt={4}>
      Apps made available through the App Store are licensed, not sold, to you. Your license to each App is subject to your prior acceptance of this Licensed Application End User License Agreement ("Standard EULA").
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      1. Scope of License
    </Text>
    <Text className="inter">
      The Application Provider grants you a nontransferable license to use the Licensed Application on any Apple-branded products that you own or control and as permitted by the Usage Rules. You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time. You may not transfer, redistribute or sublicense the Licensed Application. You may not copy, reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application.
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      2. Consent to Use of Data
    </Text>
    <Text className="inter">
      You agree that the Application Provider may collect and use technical data and related information to facilitate the provision of software updates, product support, and other services related to the Licensed Application. This information may be used, as long as it is in a form that does not personally identify you, to improve products or provide services.
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      3. External Services
    </Text>
    <Text className="inter">
      The Licensed Application may enable access to external services and websites. You agree to use these External Services at your sole risk. The Application Provider is not responsible for examining or evaluating the content or accuracy of any third-party External Services. You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity.
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      4. Warranty Disclaimer
    </Text>
    <Text className="inter">
      USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK. THE LICENSED APPLICATION AND ANY SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. THE APPLICATION PROVIDER DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND SERVICES, WHETHER EXPRESS, IMPLIED, OR STATUTORY.
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      5. Limitation of Liability
    </Text>
    <Text className="inter">
      TO THE EXTENT NOT PROHIBITED BY LAW, THE APPLICATION PROVIDER SHALL NOT BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE LICENSED APPLICATION. Total liability for all damages shall not exceed fifty dollars ($50.00).
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      6. Export Restrictions
    </Text>
    <Text className="inter">
      You may not use or export the Licensed Application except as authorized by United States law and the laws of the jurisdiction in which the Licensed Application was obtained. The Licensed Application may not be exported to U.S.-embargoed countries or to anyone on restricted government lists.
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      7. Governing Law
    </Text>
    <Text className="inter">
      This Agreement and the relationship between you and the Application Provider shall be governed by the laws of the State of California, excluding its conflicts of law provisions, unless you are a citizen of the European Union, Switzerland, Norway, or Iceland, in which case the governing law shall be the laws of your usual place of residence.
    </Text>

    <Text className="inter" mt={4}>
      These terms of use are effective as of 2024-08-17
    </Text>

    <Text className="inter" fontWeight="bold" mt={4}>
      Contact Us
    </Text>
    <Text className="inter">
      If you have any questions or suggestions about the Terms of Use, please contact us at sishir@findishq.com.
    </Text>
  </>
);

const TermsOfUse: React.FC = () => {
  return (
    <NextUIProvider>
      <NavigationBar />
      <Box bg="#f7f4f1" minHeight="100vh" py={12}>
        <Container maxW="container.md">
          <VStack spacing={8} align="stretch">
            <Heading as="h1" size="2xl" textAlign="center" className="libre-regular">
              Terms of Use
            </Heading>
            <Text className="inter">
              {termsOfUseContent}
            </Text>
          </VStack>
        </Container>
      </Box>
    </NextUIProvider>
  );
};

export default TermsOfUse;