import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home'
import Apply from './Pages/Apply';
import Blog from './Pages/Blog';
import LoveByTheNumbers from './Pages/LoveByTheNumbers';
import MappingLove from './Pages/MappingLove';
import OptingOutOfOptions from './Pages/OptingOutOfOptions';
import SabeenBlog from './Pages/SabeenBlog';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';
import { AuthProvider } from './AuthContext'; // Move AuthProvider to a separate file
import AdminLogin from './Pages/AdminLogin';
import AdminDashboard from './Pages/AdminDashboard';
import ProtectedRoute from './ProtectedRoute'; // Move ProtectedRoute to a separate file
import Support from './Pages/Support';
import TermsOfUse from './Pages/TermsOfUse';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/love-by-the-numbers-how-ratios-shape-the-dating-game" element={<LoveByTheNumbers />} />
        <Route path="/mapping-love-exploring-the-significance-of-location-in-south-asian-dating" element={<MappingLove />} />
        <Route path="/opting-out-of-options" element={<OptingOutOfOptions />} />
        <Route path="/sabeen-on-dating-parents-founder" element={<SabeenBlog />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/support" element={<Support />} />
        
        {/* Admin routes wrapped in AuthProvider */}
        <Route path="/admin/*" element={
          <AuthProvider>
            <Routes>
              <Route path="login" element={<AdminLogin />} />
              <Route path="dashboard" element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              } />
            </Routes>
          </AuthProvider>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;