import { FC } from "react";
import { Button, Link, Text, Heading, Box } from "@chakra-ui/react";
import NavigationBar from "../Components/NavigationBar";

const LoveByTheNumbers: FC = () => {
    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center gap-4 margin-unit-2 justify-center">
                <Box p={5} mx={{ base: '0', md: '10%', lg: '20%' }}>
                    <Heading as="h1" fontFamily="libre" marginBottom={4}>
                        Love by the Numbers: How Ratios Shape the Dating Game

                    </Heading>
                    <Text fontFamily="Inter" marginBottom={3}>
                        “It’s a numbers game” is something you hear all the time in regards to dating. The gamification of finding someone is not an accident, it’s a fully intended outcome of dating apps like Hinge, Tinder, Dil Mil, etc. who are simply trying to hit their business goals by keeping you on the app for as long as possible. This is also not unique to dating; DuoLingo does a great job of gamifying language-learning. And while you do hear of success stories (congrats to anyone who made it out of the trenches!), it typically comes after more than a few talking stages, ghostings, situationships, almost relationships, and maybe even a years-long relationship.
                    </Text>
                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                        Demographic Ratios
                    </Heading>
                    <Text fontFamily="Inter" marginBottom={3}>
                    Demographic ratios in different cities can significantly influence dating dynamics. For instance, a city like New York with a higher proportion of single women to single men might lead to a more competitive dating environment for women. Conversely, cities like Denver (also referred to as Menver) with more single men than women seem like a paradise for women looking for love.

                    </Text>
                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                        Interaction Ratios
                    </Heading>
                    <Text fontFamily="inter" marginBottom={1}>
                    Interaction Ratios is something we’re heavily focused on as we develop the Ishq app and the community we’re building. For example, for every ‘like’ you get, how many do you match with? From the women we’ve spoken to, this can be as bad as 1 in 25. To us, that’s a clear indication of the lack of quality of profiles on the other apps. Our goal is to improve upon this and bring that ratio down to 1 in 10 or 1 in 5.
                    </Text>
                    <Text fontFamily="inter" marginBottom={3}>
                    From a match, what’s the ratio of folks you have a meaningful conversation with? And from there, where does it go? At each step in the process of getting to know someone, improving the ratios essentially increases the chances of finding your person, but also reflects the overall quality of the user base.
                    </Text>
                    <Heading as="h3" fontFamily="libre-baskerville" fontWeight="bold" fontSize="lg" marginBottom={3}>
                        The Impact
                    </Heading>
                    <Text fontFamily="inter" marginBottom={5}>
                    Ultimately these ratios have an impact on anyone who is beholden to them, because the goal is to find your person. All you need is one, but it feels like the current dating system is built to help you find 10 mediocre ones. With Ishq, we’re being extremely judicious in the quality of people who join this community in order to ditch the numbers game and simply focus on developing that one connection.
                    </Text>
                    <Text fontFamily="libre" marginBottom={5}>
                        If you enjoy writing about dating, South Asian culture & identity, or any other relevant topics and want to contribute to our blog, email us at info@findishq.com. We would love to feature you.
                    </Text>

                </Box>
                <Button padding={7} fontFamily={'inter'} borderRadius={40} backgroundColor={'#151313'} color={'#f7f4f1'} as={Link} href="/apply" variant="flat" marginBottom={10}>
                    Apply
                </Button>

            </div>

        </div>


    )
}

export default LoveByTheNumbers;